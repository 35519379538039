import React from "react";
import "./Footer.scss"
import { Link } from "react-scroll";
import { useRef, useLayoutEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";


function Footer() {

    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0, offsetHeight: 0 });

    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight,
                offsetHeight: -Math.abs(targetRef.current.offsetHeight / 100 / 11)
            });
        }
    }, []);

    return (
        <div className="footer-container" id="footer">

            <div className="footer-main-container">

                <div className="footer-top-container">

                    <div className="footer-top-text-container">
                        <div className="footer-links-container">

                            <h1 className="footer-contact-title"><span className="footer-contact-title-color">Mapa</span> del sitio</h1>
                            <Link className="footer-link" to="home" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Inicio</Link>
                            <Link className="footer-link" to="about" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>¿Quiénes Somos?</Link>
                            <Link className="footer-link" to="services" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Nuestros Servicios</Link>
                            <Link className="footer-link" to="contact" spy={true} smooth={true} offset={dimensions.offsetHeight} duration={1500}>Contacto</Link>

                        </div>

                        <div className="footer-contact-container">

                            <h1 className="footer-contact-title">Contacto</h1>

                            <div className="footer-contact-single">
                                <FontAwesomeIcon className="footer-icon" icon={faWhatsapp}></FontAwesomeIcon>
                                <h3 className="footer-contact-single-text">+52 722 2737210</h3>
                            </div>

                            <div className="footer-contact-single">
                                <FontAwesomeIcon className="footer-icon" icon={faWhatsapp}></FontAwesomeIcon>
                                <h3 className="footer-contact-single-text">+52 722 4244461</h3>
                            </div>

                            <div className="footer-contact-single">
                                <FontAwesomeIcon className="footer-icon" icon={faEnvelope}></FontAwesomeIcon>
                                <h3 className="footer-contact-single-text">actitud.imagen@gmail.com</h3>
                            </div>
                        </div>

                    </div>


                    <div className="footer-iframes-container">
                        <div className="footer-iframe-container">
                            <h3 className="footer-iframe-title">Sucursal <span className="footer-iframe-title-color">Sauces 4</span></h3>
                            <iframe
                                className="footer-iframe"
                                title="googleLocation"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d279.76669416107427!2d-99.58878051363708!3d19.36581989603224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d274ff7667618f%3A0x8e6f54ba58b8ca1d!2zw4l4aXRv!5e0!3m2!1ses!2smx!4v1698272606847!5m2!1ses!2smx"
                                width="fit-content"
                                height="fit-content"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade">
                            </iframe>
                        </div>

                        <div className="footer-iframe-container">
                            <h3 className="footer-iframe-title">Sucursal <span className="footer-iframe-title-color">Hacienda del Valle II</span></h3>
                            <iframe
                                className="footer-iframe"
                                title="googleLocation"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d941.0102025895018!2d-99.58178176204066!3d19.36738552170858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d2759b9526876f%3A0x6e02d61586a4ea9b!2sConsultorio%20m%C3%A9dico%20malederma!5e0!3m2!1ses!2smx!4v1704728970503!5m2!1ses!2smx"
                                width="fit-content"
                                height="fit-content"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade">
                            </iframe>
                        </div>

                        <div className="footer-iframe-container">
                            <h3 className="footer-iframe-title">Sucursal <span className="footer-iframe-title-color">Santín</span></h3>
                            <iframe
                                className="footer-iframe"
                                title="googleLocation"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d940.9962783416642!2d-99.58587484517177!3d19.3697972538646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d274f93f2ed925%3A0x273a59039018fc24!2sConsultorios%20M%C3%A9dicos%20Y%20Farmacia%20Santo%20Domingo!5e0!3m2!1ses!2smx!4v1704727711664!5m2!1ses!2smx"
                                width="fit-content"
                                height="fit-content"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade">
                            </iframe>
                        </div>
                    </div>

                </div>
            </div>


            <div className="footer-bottom-container">
                <h3 className="footer-bottom-name">Sitio web desarrollado por: Mazzei Facundo</h3>

                <div className="footer-bottom-social-container">
                    <FontAwesomeIcon className="footer-bottom-icon" icon={faWhatsapp}></FontAwesomeIcon>
                    <FontAwesomeIcon className="footer-bottom-icon" icon={faInstagram}></FontAwesomeIcon>
                    <FontAwesomeIcon className="footer-bottom-icon" icon={faFacebook}></FontAwesomeIcon>
                </div>
            </div>

            <div className="footer-credit-icons-container">
                <a className="footer-credit-icons" href="https://www.flaticon.com/free-animated-icons/swipe-left" title="swipe left animated icons">Swipe left animated icons created by Freepik - Flaticon</a>
            </div>

        </div>
    )
}

export default Footer;