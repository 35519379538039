import React from "react";
import "./About.scss"
import { Link } from "react-scroll";
import { useRef, useLayoutEffect, useState } from 'react';
import titlelogo from "../../resources/images/title-logo.webp"
import about from "../../resources/images/About.webp"
import abtIconOne from "../../resources/images/Abt-icon-1.webp"
import abtIconTwo from "../../resources/images/Abt-icon-2.webp"
import abtIconThree from "../../resources/images/Abt-icon-3.webp"
import abtIconFour from "../../resources/images/Abt-icon-4.webp"

function About() {

    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0, offsetHeight: 0 });

    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight,
                offsetHeight: -Math.abs(targetRef.current.offsetHeight / 100 / 11)
            });
        }
    }, []);

    const handleScroll = event => {
        console.log('scrollTop: ', event.currentTarget.scrollTop);
        console.log('offsetHeight: ', event.currentTarget.offsetHeight);
    };

    return (
        <div className="abt-container" id="about">

            <div className="abt-img-container">
                <img className="abt-img" alt="Cargando..." src={about}></img>
            </div>

            <div className="abt-text-container">

                <div className="abt-text-title-container">
                    <img className="abt-text-title-img" src={titlelogo} alt="Cargando..."></img>
                    <h1 className="abt-text-title">Somos <span className="abt-text-title-color">Malederma</span></h1>
                </div>

                <div className="abt-textbox">Malederma Spa te invita a desconectar, relajarte y rejuvenecer con nosotros. Conoce nuestras instalaciones y descubre un nuevo nivel de relajación en un entorno donde el bienestar y la tranquilidad son nuestra prioridad.</div>

                <div className="abt-icon-rows-container">
                    <div className="abt-icon-row">
                        <div className="abt-icon-container">
                            <img className="abt-icon" src={abtIconOne} alt="Cargando..."></img>
                            <h3 className="abt-icont-text">Masajes</h3>
                        </div>

                        <div className="abt-icon-container">
                            <img className="abt-icon" src={abtIconTwo} alt="Cargando..."></img>
                            <h3 className="abt-icont-text">Facial</h3>
                        </div>
                    </div>

                    <div className="abt-icon-row">
                        <div className="abt-icon-container">
                            <img className="abt-icon" src={abtIconThree} alt="Cargando..."></img>
                            <h3 className="abt-icont-text">Consultorio</h3>
                        </div>

                        <div className="abt-icon-container">
                            <img className="abt-icon" src={abtIconFour} alt="Cargando..."></img>
                            <h3 className="abt-icont-text">Farmacia</h3>
                        </div>
                    </div>
                </div>


                <div className="abt-text-buttons-container">

                    <Link
                        className="abt-text-button"
                        to="services"
                        spy={true}
                        smooth={true}
                        offset={dimensions.offsetHeight}
                        duration={1500}>Servicios
                    </Link>

                    <Link
                        className="abt-text-button"
                        to="contact"
                        spy={true}
                        smooth={true}
                        offset={dimensions.offsetHeight}
                        duration={1500}>Contáctanos
                    </Link>
                </div>

            </div>

        </div>
    )
}

export default About;