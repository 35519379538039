import React, { useState, useEffect } from "react";
import "./DidUKnow.scss";
import titlelogo from "../../resources/images/title-logo.webp";
import didyouknow from "../../resources/images/didyouknow.webp";

function DidUKnow() {
  const facts = [
    "Los masajes tienen una larga historia que se remonta a la antigüedad. Se cree que se practicaban en la antigua China hace más de 3,000 años y en la India hace más de 5,000 años. Desde entonces, han evolucionado y se han adaptado en diversas culturas de todo el mundo.",
    "Los masajes no solo son relajantes, sino que también ofrecen numerosos beneficios para la salud. Pueden ayudar a aliviar el estrés, reducir la tensión muscular, mejorar la circulación sanguínea, aliviar el dolor y aumentar la flexibilidad.",
    "Existen una amplia variedad de tipos de masajes, cada uno con enfoques y técnicas específicas. Algunos ejemplos incluyen el masaje sueco, el masaje tailandés, el masaje deportivo y el masaje de tejido profundo. Cada tipo de masaje está diseñado para abordar necesidades específicas del cuerpo y la mente.",
  ];

  const [currentFactIndex, setCurrentFactIndex] = useState(0);
  const [showFact, setShowFact] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowFact(false); // Start fade-out animation
      setTimeout(() => {
        setCurrentFactIndex((prevIndex) => (prevIndex + 1) % facts.length);
        setShowFact(true); // Start fade-in animation
      }, 1000); // Wait for fade-out animation to complete (1s)
    }, 10000); // Change every 10 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="did-u-container">
      <div className="did-u-title-container">
        <img className="did-u-title-img" src={titlelogo} alt="Cargando..." />
        <h1 className="did-u-title">
          ¿Sabías <span className="did-u-title-color">Que</span>?
        </h1>
      </div>

      <div className="did-u-facts-container">
        <div className="did-u-single-fact-container">
          <h4 className={`did-u-single-fact-text ${showFact ? "show" : ""}`}>
            {facts[currentFactIndex]}
          </h4>
        </div>
      </div>
      <img className="did-u-know-img" src={didyouknow} alt="Cargando..." />
    </div>
  );
}

export default DidUKnow;
