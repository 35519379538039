import React, { useState } from "react";
import "./Services.scss";
import titlelogo from "../../resources/images/title-logo.webp";
import abtIconOne from "../../resources/images/Abt-icon-1.webp";
import abtIconTwo from "../../resources/images/Abt-icon-2.webp";
import abtIconThree from "../../resources/images/Abt-icon-3.webp";
import abtIconFour from "../../resources/images/Abt-icon-4.webp";
import abtBottom from "../../resources/images/abt-bottom.webp";

function Services() {
  const [clickedSquare, setClickedSquare] = useState(null);

  const servicesData = [
    {
      icon: abtIconOne,
      title: "Masajes",
      text: "Nuestros expertos terapeutas están listos para consentirte con masajes diseñados para aliviar tensiones, liberar el estrés y restaurar tu equilibrio interior.",
    },
    {
      icon: abtIconTwo,
      title: "Facial",
      text: "Haz de tu cuidado facial una experiencia relajante y efectiva. Reserva tu cita ahora y descubre el poder transformador de un masaje facial en Malederma.",
    },
    {
      icon: abtIconThree,
      title: "Consultorio",
      text: "Desde chequeos preventivos hasta consultas especializadas, estamos comprometidos con tu bienestar integral.",
    },
    {
      icon: abtIconFour,
      title: "Farmacia",
      text: "En Malederma, nos enorgullece ofrecerte mucho más que medicamentos. Nuestra farmacia es tu aliada para el bienestar, brindándote un servicio y productos de calidad.",
    },
  ];

  const handleSquareClick = (index) => {
    setClickedSquare(index === clickedSquare ? null : index);
  };

  return (
    <div className="services-container" id="services">
      <div className="services-title-container">
        <img className="services-title-img" src={titlelogo} alt="Cargando..." />
        <h1 className="services-title">
          Nuestros <span className="services-title-color">Servicios</span>
        </h1>
      </div>

      <div className="services-square-group">
        {servicesData.map((service, index) => (
          <div
            key={index}
            className={`services-square-single ${index === clickedSquare ? "clicked" : ""}`}
            onClick={() => handleSquareClick(index)}
          >
            <img className="services-square-icon" src={service.icon} alt="Cargando..." />
            <h2 className="services-square-title">{service.title}</h2>
            <h4 className="services-square-textbox">{service.text}</h4>
          </div>
        ))}
      </div>

      <img className="services-bottom-img" src={abtBottom} alt="Cargando..." />
    </div>
  );
}

export default Services;
