import React from "react";
import "./Contact.scss"
import titlelogo from "../../resources/images/title-logo.webp"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faWhatsapp, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'


function Contact() {

    return (
        <div className="contact-container" id="contact">

            <div className="contact-title-container">
                <img className="contact-title-img" src={titlelogo} alt="Cargando..."></img>
                <h1 className="contact-title">Encuéntranos <span className="contact-title-color">en...</span></h1>
            </div>

            <div className="contact-icons-container">

                <a className="contact-icon-container" href="https://www.facebook.com/SPAMALEDERMA" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon className="footer-icon" icon={faFacebook}></FontAwesomeIcon>
                </a>

                <a className="contact-icon-container" href="https://wa.me/+5217224244461" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon className="footer-icon" icon={faWhatsapp}></FontAwesomeIcon>
                </a>

            </div>

            <div className="contact-icons-container">

                <a className="contact-icon-container" href="https://www.instagram.com/malederma/" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon className="footer-icon" icon={faInstagram}></FontAwesomeIcon>
                </a>

                <a className="contact-icon-container" href="https://www.tiktok.com/@malederma.spa" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon className="footer-icon" icon={faTiktok}></FontAwesomeIcon>
                </a>

            </div>

           

        </div>
    )
}

export default Contact;