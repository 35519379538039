import React from "react";
import "./Promotions.scss"

// core version + navigation, pagination modules:
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import titleLogo from "../../resources/images/title-logo.webp"

import swipeLeft from "../../resources/images/swipe-left.gif"

import Promotion1 from './../../resources/images/promotion1.webp'
import Promotion2 from './../../resources/images/promotion2.webp'
import Promotion3 from './../../resources/images/promotion3.webp'
import Promotion4 from './../../resources/images/Promotion4.webp'

function Promotions() {
    return (
        <div className="promotions-container" id="promotions">

            <div className="promotions-title-container">
                <img className="promotions-title-img" src={titleLogo} alt="Cargando..."></img>
                <div className="promotions-title">Promociones <span className="promotions-title-color">Malederma</span></div>
            </div>

            <div className="promotions-slider-container">
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    loop={true}
                    slidesPerView={'2'}
                    coverflowEffect={
                        {
                            rotate: 0,
                            stretch: 0,
                            depth: 100,
                            modifier: 2.5,
                        }
                    }
                    pagination={{ el: ".swiper-pagination", clickable: true }}
                    navigation={
                        {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                            clickable: true,
                        }
                    }
                    modules={[EffectCoverflow, Pagination, Navigation]}
                    className="swiper-container"

                >
                    <SwiperSlide>
                        <img className="swiper-img" src={Promotion1} alt="Cargando"></img>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img className="swiper-img" src={Promotion2} alt="Cargando"></img>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img className="swiper-img" src={Promotion3} alt="Cargando"></img>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img className="swiper-img" src={Promotion4} alt="Cargando"></img>
                    </SwiperSlide>

                    <div className="slider-tip">¡Arrastra las imágenes para seguir viendo!</div>


                    <div className="swiper-interface">
                        <div className="slider-controller">
                            <button className="swiper-button-prev slider-arrow"></button>
                        </div>

                        <img className="swipe-left-icon" src={swipeLeft} alt="Cargando..."></img>

                        <div className="slider-controller">
                            <button className="swiper-button-next slider-arrow"></button>
                        </div>
                    </div>

                </Swiper>
            </div>

            <div className="promotions-slider-mobile-container">
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    loop={true}
                    slidesPerView={'1'}
                    coverflowEffect={
                        {
                            rotate: 0,
                            stretch: 0,
                            depth: 0,
                            modifier: 1,
                        }
                    }
                    pagination={{ el: ".swiper-pagination", clickable: true }}
                    navigation={
                        {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                            clickable: true,
                        }
                    }
                    modules={[EffectCoverflow, Pagination, Navigation]}
                    className="swiper-container"

                >
                    <SwiperSlide>
                        <img className="swiper-img" src={Promotion1} alt="Cargando"></img>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img className="swiper-img" src={Promotion2} alt="Cargando"></img>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img className="swiper-img" src={Promotion3} alt="Cargando"></img>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img className="swiper-img" src={Promotion4} alt="Cargando"></img>
                    </SwiperSlide>

                    <div className="slider-tip">¡Arrastra las imágenes para seguir viendo!</div>


                    <div className="swiper-interface">
                        <div className="slider-controller">
                            <button className="swiper-button-prev slider-arrow"></button>
                        </div>

                        <img className="swipe-left-icon" src={swipeLeft} alt="Cargando..."></img>

                        <div className="slider-controller">
                            <button className="swiper-button-next slider-arrow"></button>
                        </div>
                    </div>

                </Swiper>
            </div>
        </div>
    )
}

export default Promotions;