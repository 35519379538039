import './App.css';
import Nav from './components/Nav/Nav';
import Home from './components/Home/Home';
import About from './components/About/About';
import Stats from './components/Stats/Stats';
import Values from './components/Values/Values';
import Services from './components/Services/Services';
import DidUKnow from './components/DidUKnow/DidUKnow';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Promotions from './components/Promotions/Promotions';

function App() {
  return (
    <div className="App">
      <Nav />
      <Home className="Home" />
      <About className="About" />
      <Stats className="Stats" />
      <Services className="Services" />
      <Promotions className="Promotions" />
      <DidUKnow className="DidUKnow" />
      <Values className="Values" />
      <Contact className="Contact" />
      <Footer className="Footer" />
    </div>
  );
}

export default App;
