import React from "react";
import "./Values.scss";
import titlelogo from "../../resources/images/title-logo.webp";
import valuesImg1 from "../../resources/images/values-img-1.webp"
import valuesImg2 from "../../resources/images/values-img-2.webp"
import valuesImg3 from "../../resources/images/values-img-3.webp"

function Values() {

    return (
        <div className="values-container" id="values">

            <div className="values-title-container">
                <img className="values-title-img" src={titlelogo} alt="Cargando..."></img>
                <h2 className="values-title">Nuestra <span className="values-title-color">Filosofía</span></h2>
            </div>

            <div className="values-singles-container">
                
                <div className="values-single-value-container">

                    <img className="values-single-img" src={valuesImg1} alt="Cargando..."></img>

                    <div className="values-single-title-container">
                        <img className="values-title-single-img" src={titlelogo} alt="Cargando..."></img>
                        <h3 className="value-single-title">Nuestros <span className="value-single-title-color">Valores</span></h3>
                    </div>

                    <h4 className="values-single-text">Integridad, con compromiso, haciendo siempre lo correcto, Honestidad, nos comprometemos a que prevalezca la confianza del cliente en nuestros productos y Amor, satisfacción y tranquilidad en hacer las cosas de manera correcta y con compromiso</h4>

                </div>

                <div className="values-single-value-container">

                    <img className="values-single-img" src={valuesImg2} alt="Cargando..."></img>

                    <div className="values-single-title-container">
                        <img className="values-title-single-img" src={titlelogo} alt="Cargando..."></img>
                        <h3 className="value-single-title">Nuestra <span className="value-single-title-color">Misión</span></h3>
                    </div>

                    <h4 className="values-single-text">Proveer de productos y servicios de salud y belleza únicos e innovadores a nuestros clientes.</h4>

                </div>

                <div className="values-single-value-container">

                    <img className="values-single-img" src={valuesImg3} alt="Cargando..."></img>

                    <div className="values-single-title-container">
                        <img className="values-title-single-img" src={titlelogo} alt="Cargando..."></img>
                        <h3 className="value-single-title">Nuestra <span className="value-single-title-color">Visión</span></h3>
                    </div>

                    <h4 className="values-single-text">Ser la opción preferida de nuestros clientes, superando sus expectativas de calidad y servicio, mejorando continuamente</h4>

                </div>

            </div>


        </div>
    );
}

export default Values;
