import React from "react";
import "./Stats.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

function Stats() {

  return (
    <div className="writeus-container">

      <div className="writeus-text-container">
        <h2 className="writeus-title">¡Agenda tu cita!</h2>
        <h4 className="writeus-text">¡Escribenos por cualquier duda o para agendar tu cita, nuestro horario de trabajo es de 09:00 AM a 12:00 PM, estamos para servirle!</h4>
      </div>

      <div className="writeus-button-container">
        <a href="https://wa.me/+5217224244461" className="writus-button"  target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon className="writeus-icon" icon={faWhatsapp}></FontAwesomeIcon>
          ¡Contactanos!
        </a>
      </div>

    </div>
  );
}

export default Stats;
