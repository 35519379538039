import React from "react";
import "./Home.scss"
import logo from "../../resources/images/logo.webp"
import { Link } from "react-scroll";
import { useRef, useLayoutEffect, useState } from 'react';

function Home() {

    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0, offsetHeight: 0 });

    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight,
                offsetHeight: -Math.abs(targetRef.current.offsetHeight / 100 / 11)
            });
        }
    }, []);

    const handleScroll = event => {
        console.log('scrollTop: ', event.currentTarget.scrollTop);
        console.log('offsetHeight: ', event.currentTarget.offsetHeight);
    };

    return (
        <div className="h-container" onScroll={handleScroll} ref={targetRef} id="home">
            <div className="h-title-container">
                <img src={logo} alt="Cargando..." className="h-title-img"></img>
                <div className="h-title-text-container">
                    <h1 className="h-title-text-title">Malederma</h1>
                    <h3 className="h-title-text-subtitle">Consultorio y SPA</h3>
                </div>
            </div>

            <h4 className="h-textbox">
            Descubre la paz interior y la belleza exterior en un entorno de elegancia y cuidado personal. Tu camino hacia el equilibrio y la renovación comienza aquí.
            </h4>

            <Link
                className="h-btn"
                to="about"
                spy={true}
                smooth={true}
                offset={dimensions.offsetHeight + 150}
                duration={1500}>Conocenos
            </Link>
        </div>
    )
}

export default Home;